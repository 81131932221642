import { useEffect } from "react";

import useWindowSizes from "hooks/use-window-sizes";

export const useCloseInDesktop = (closeFunction: VoidFunction) => {
  const { isMobileWidth } = useWindowSizes();

  useEffect(() => {
    if (!isMobileWidth) {
      closeFunction();
    }
  }, [isMobileWidth]);
};
